import React from "react"
import EmergencyMedicalCareBanner from "../components/EmergencyMedicalCareBanner"
import Seo from "../components/Seo"
import Testimonials from "../components/Testimonials"
import withLayout from "../layout/withLayout"
const CovidPage = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title="COVID 19 Care Center" description="COVID 19 Care Center at Nemcare Hospital Tezpur" siteMetadata={siteData} />
      <div className="page-title-area page-title-bg-covid">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>COVID 19 CARE CENTER</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>COVID CARE</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="research-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <div className="research-details-header">
                <h2>COVID and Post COVID Care at Nemcare Hospital Tezpur</h2>
                <p className="mt-4">
                  Due to rising COVID19 cases in India, delivering the best,
                  most efficient primary care is at the importance in medicine
                  today. Because primary care serves as the foundation of all
                  medical care—both care delivered and care averted. Despite
                  facing serious challenges we believe in the potential of COVID
                  care at primary level to diagnose, treat and contain the
                  disease with full spectrum of care available when needed.
                </p>
                <p>
                  That is why we are committed to uncovering, promoting, and
                  fighting for the best and most efficient ways to deliver
                  high-quality primary care at the locality. At the Center, we
                  create programs and explore advanced medical treatments to
                  help COVID 19 patients including prevention of post COVID
                  illnesses to navigate challenges in healthcare.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-lg-6">
              <img
                src="/img/covid-test.jpg"
                style={{ width: "100%", height: "100%" }}
                alt="covid-image"
              ></img>
            </div>

            <div className="col-12 col-lg-6">
              <div className="research-details-desc">
                <h3>COVID Center Speciality</h3>
                <div class="research-details-footer-features">
                  <ul>
                    <li>
                      <i class="fas fa-check"></i> General Ward with 9 Bed
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Private Non-AC Cabin with 3
                      Bed
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Private AC Cabin with 4 Bed
                    </li>
                    <li>
                      <i class="fas fa-check"></i> ICU with 10 Bed
                    </li>
                  </ul>
                </div>

                <div class="research-details-footer-features mt-4">
                  <ul>
                    <li>
                      <i class="fas fa-check"></i> Covid Consultants
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Resident Doctors (24x7
                      Available)
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Nursing Staffs (24x7
                      available)
                    </li>
                    <li>
                      <i class="fas fa-check"></i> 24x7 Housekeeping
                    </li>
                  </ul>
                </div>

                <h3 className="mt-4">
                  Extra Facilities alloted separately at COVID Center
                </h3>
                <div class="research-details-footer-features">
                  <ul>
                    <li>
                      <i class="fas fa-check"></i> 24x7 Oxygen Supply
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Portable Ventilator
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Portable X-Ray and ECG
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <EmergencyMedicalCareBanner siteData={siteData} />

      <Testimonials />
    </>
  )
})

export default CovidPage
